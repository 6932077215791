@import url(fontiran.css);

body {
  direction: rtl;
  font-family: iranyekan !important;
}

.slick-initialized {
  /* -webkit-clip-path: circle(37% at 80% 40%); */
  /* clip-path: circle(40% at 80% 50%); */
  position: 'relative';
}
.carousel-image {
  border-top: 1px solid black !important;
  border-top: 1px solid black !important;
}

#topCarousel .slick-list {
  border-top-right-radius: 200px;
  box-shadow: 0 30px 100px 0px rgb(0 0 0 / 40%);
  height: 500px;
}


@media (max-width: 600px) {
  #topCarousel .slick-list {
    height: 370px;
  }
}

#projects .slick-list {
  height: 500px;
}

@media (max-width: 600px) {
  #projects .slick-list {
    height: 370px;
  }
}
